@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'George';
	src: url('./Assets/fonts/georgia_bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'George';
	src: url('./Assets/fonts/georgia_regular.ttf');
	font-weight: 400;
}

* {
	outline: none;
	scroll-padding-top: 80px;
}

h1,
h2,
h3,
h4 {
	font-family: 'Oxanium';
}

.scrollbar::-webkit-scrollbar {
	display: none;
	background-color: transparent;
}

.linear-gradient {
	background-image: linear-gradient(
		to right,
		rgb(2, 2, 2, 0.6),
		rgba(5, 5, 5, 0.6)
	);
}

.display::-webkit-scrollbar {
	display: none;
	background-color: transparent;
}

@media (max-width: 1020px) {
	.bgNavbar {
		background-image: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.99),
				rgba(0, 0, 0, 0.9)
			),
			url('./Assets/images/oldHuman-1.webp');
		opacity: 0.97;
		background-size: cover;
		background-position: right;
		background-repeat: no-repeat;
	}

	.subscribeMobile {
		background-image: url('./Assets/images/subscribe-image-mobile.jpg');
	}
}

.marquee {
	height: 200px;
	width: 100vw;
	overflow: hidden;
	position: relative;
}

.content {
	--seconds: 20s;
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	-moz-animation: scroll-left 2s ease-in-out infinite;
	-webkit-animation: scroll-left 2s ease-in-out infinite;
	animation: scroll-left var(--seconds) ease-in-out infinite;
}

@-moz-keyframes scroll-left {
	0% {
		-moz-transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%);
	}
}

@-webkit-keyframes scroll-left {
	0% {
		-webkit-transform: translateX(100%);
	}
	100% {
		-webkit-transform: translateX(-100%);
	}
}

@keyframes scroll-left {
	0% {
		-moz-transform: translateX(100%);
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
@media (max-width: 768px) {
	.content {
		--seconds: 17s;
	}
	@-moz-keyframes scroll-left {
		0% {
			-moz-transform: translateX(100%);
		}
		100% {
			-moz-transform: translateX(-150%);
		}
	}

	@-webkit-keyframes scroll-left {
		0% {
			-webkit-transform: translateX(100%);
		}
		100% {
			-webkit-transform: translateX(-150%);
		}
	}

	@keyframes scroll-left {
		0% {
			-moz-transform: translateX(100%);
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}
		100% {
			-moz-transform: translateX(-150%);
			-webkit-transform: translateX(-150%);
			transform: translateX(-150%);
		}
	}
}

@media (max-width: 425px) {
	.content {
		--seconds: 15s;
	}
	@-moz-keyframes scroll-left {
		0% {
			-moz-transform: translateX(100%);
		}
		100% {
			-moz-transform: translateX(-200%);
		}
	}

	@-webkit-keyframes scroll-left {
		0% {
			-webkit-transform: translateX(100%);
		}
		100% {
			-webkit-transform: translateX(-200%);
		}
	}

	@keyframes scroll-left {
		0% {
			-moz-transform: translateX(100%);
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}
		100% {
			-moz-transform: translateX(-200%);
			-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
		}
	}
}

.ant-form-item-explain-error {
	display: none;
}
